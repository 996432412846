<template>
  <div>
    <QuestionRadio question="* Tipologia di azienda/ente" name="company_type" :edit="edit" :options="[
        { value: 1, label: 'Azienda privata' },
        { value: 2, label: 'Azienda a partecipazione pubblica (totale e mista)' },
        { value: 5, label: 'Università' },
        { value: 6, label: 'Ente di ricerca' },
        { value: 3, label: 'Amministrazione dello Stato, anche ad ordinamento autonomo (art. 1, c. 2, d.lgs. 165/2001)' },
        { value: 4, label: 'Azienda pubblica', tooltip: 'Impresa a partecipazione statale, azienda autonoma dello Stato, enti autonomi di gestione che amministrano le partecipazioni statali, enti pubblici economici' },
      ]" />

    <div class="question">
      <template v-if="isNotUniversita">
        <QuestionNumberRow
          :question="'* Totale addetti/e, nell\'anno solare ' + referenceYear"
          tooltip="Ci si riferisce al numero di persone e non al numero di contratti"
          name="company_nb_workers"
          :edit="edit"
        />
      </template>

      <template v-if="isUniversita">
        <div class="question">
          <label>
            <span class="question-title">* Università di appartenenza</span>
            <VueSelect v-if="edit" name="company_university" id="company_university" :value="university"
                         @input="setUniversity" :options="universities" track-by="id" label="name"
                         placeholder="Digita il nome per cercare l'università" :allow-empty="false"
            >
            </VueSelect>
            <template v-else v-for="university in universities">
            <span v-if="surveyData.company_university === university.id" class="d-block fake-input fake-input-text" :key="university.id">
              {{ university.name }}
            </span>
            </template>
          </label>
        </div>

        <button v-if="canFillUniData" class="btn btn-outline-secondary mb-4" @click="fillUniData">Recupera dati automaticamente</button>

        <QuestionNumberRow
          :question="'* Totale personale docente (PO + PA + RDT + RU + AR), nell\'anno solare ' + referenceYear"
          tooltip="Ci si riferisce al numero di persone e non al numero di contratti"
          name="company_nb_teachers"
          :edit="edit"
        />
        <QuestionNumberRow
          :question="'* Totale personale tecnico-amministrativo, nell\'anno solare ' + referenceYear"
          tooltip="Ci si riferisce al numero di persone e non al numero di contratti"
          name="company_nb_technical"
          :edit="edit"
        />
        <QuestionNumberRow
          :question="'Totale studenti e studentesse, nell\'anno accademico ' + referenceYear"
          name="company_nb_students"
          :edit="edit"
        />
      </template>
    </div>

    <template v-if="isNotUniversita">
      <QuestionText
        question="* Denominazione/Ragione sociale"
        name="company_name"
        :edit="edit"
      />

      <div class="question">
        <label>
          <span class="question-title">Stabilimento/Unità produttiva</span>
          <input v-if="edit" type="text" class="longtext" :value="surveyData.company_productive_unit" @input="setText('company_productive_unit', $event)">
          <span v-else class="d-block fake-input fake-input-text">{{ surveyData.company_productive_unit }}</span>
        </label>
      </div>

      <div class="question">
        <label>
          <span class="question-title">* CCNL applicato</span>
          <input v-if="edit" type="text" class="longtext" :value="surveyData.company_primary_collective_agreement" @input="setText('company_primary_collective_agreement', $event)" required>
          <span v-else class="d-block fake-input fake-input-text">{{ surveyData.company_primary_collective_agreement }}</span>
        </label>
      </div>

      <div class="question" v-if="surveyData.company_primary_collective_agreement">
        <label>
          <span class="question-title">Se esistono ulteriori CCNL applicati, indicarli</span>
          <input v-if="edit" type="text" class="longtext" :value="surveyData.company_secondary_collective_agreement" @input="setText('company_secondary_collective_agreement', $event)">
          <span v-else class="d-block fake-input fake-input-text">{{ surveyData.company_secondary_collective_agreement }}</span>
        </label>
      </div>

      <div class="question">
        <label>
          <span class="question-title">* Anno di costituzione</span>
          <input v-if="edit" type="number" min="1800" :max="surveyData.year" class="shorttext" :value="surveyData.company_establishment_year" @input="setTextAsInteger('company_establishment_year', $event)" required>
          <span v-else class="d-block fake-input fake-input-text">{{ surveyData.company_establishment_year }}</span>
        </label>
      </div>

      <div class="question">
        <label>
          <span class="question-title">* Codice ATECO 2007</span>
          <input v-if="edit" type="text" class="shorttext" :value="surveyData.company_ateco2007_code" @input="setText('company_ateco2007_code', $event)" required>
          <span v-else class="d-block fake-input fake-input-text">{{ surveyData.company_ateco2007_code }}</span>
        </label>
      </div>

      <div class="question">
        <label>
          <span class="question-title">Indirizzo (sede legale)</span>
          <input v-if="edit" type="text" class="longtext" :value="surveyData.company_address" @input="setText('company_address', $event)">
          <span v-else class="d-block fake-input fake-input-text">{{ surveyData.company_address }}</span>
        </label>
      </div>

      <div class="question" v-if="(isAziendaPrivata || isAziendaPartecipata || isAziendaPubblica) && totalWorkers > 99">
        <span class="question-title">
          Avete presentato – come previsto dall'art.46 del <span data-referring-to-law @click="showLaw('dlgs-198-2006')">D.Lgs. 198/2006</span> (già art. 9 della Legge 125/91) – il rapporto biennale relativo all'anno {{ referenceYear }} al/la <span data-explained-by-term @click="showTerm('consigliera-di-parita')">consigliere/a regionale di parità</span> riguardante la situazione del vostro personale, maschile e femminile?
        </span>
        <fieldset class="radio-set">
          <label>
            <input type="radio" :value="0" :checked="0 === surveyData.company_has_fulfilled_art46" @change="setRadio('company_has_fulfilled_art46', $event)">
            <span>No</span>
          </label>
          <label>
            <input type="radio" :value="1" :checked="1 === surveyData.company_has_fulfilled_art46" @change="setRadio('company_has_fulfilled_art46', $event)">
            <span>Sì</span>
          </label>
        </fieldset>
      </div>

      <div class="question" v-if="isAmministrazioneStatale">
          <span class="question-title">
            Avete mai redatto il <span data-explained-by-term @click="showTerm('piano-azioni-positive')">piano triennale di azioni positive</span> (Ex art. 48 <span data-referring-to-law @click="showLaw('dlgs-198-2006')">D.Lgs. 198/2006</span>)?
          </span>

        <fieldset class="radio-set">
          <label>
            <input type="radio" :value="0" :checked="0 === surveyData.company_has_fulfilled_art48" @change="setRadio('company_has_fulfilled_art48', $event)">
            <span>No</span>
          </label>
          <label>
            <input type="radio" :value="1" :checked="1 === surveyData.company_has_fulfilled_art48" @change="setRadio('company_has_fulfilled_art48', $event)">
            <span>Sì</span>
          </label>
        </fieldset>

        <div class="question indented" v-if="isAmministrazioneStatale && surveyData.company_has_fulfilled_art48 === 1">
          <span class="question-title">L'ultimo documento del piano triennale di azioni positive redatto include l'anno {{ referenceYear }}?</span>

          <fieldset class="radio-set">
            <label>
              <input type="radio" :value="0" :checked="0 === surveyData.company_art48_fulfilled_for_reference_year" @change="setRadio('company_art48_fulfilled_for_reference_year', $event)">
              <span>No</span>
            </label>
            <label>
              <input type="radio" :value="1" :checked="1 === surveyData.company_art48_fulfilled_for_reference_year" @change="setRadio('company_art48_fulfilled_for_reference_year', $event)">
              <span>Sì</span>
            </label>
          </fieldset>
        </div>

        <div class="question indented" v-if="isAmministrazioneStatale && surveyData.company_has_fulfilled_art48 === 1">
          <span class="question-title">Avete mai effettuato una valutazione di efficacia dei precedenti piani triennali di azioni positive?</span>

          <fieldset class="radio-set">
            <label>
              <input type="radio" :value="0" :checked="0 === surveyData.company_has_judged_art48_effectiveness" @change="setRadio('company_has_judged_art48_effectiveness', $event)">
              <span>No</span>
            </label>
            <label>
              <input type="radio" :value="2" :checked="2 === surveyData.company_has_judged_art48_effectiveness" @change="setRadio('company_has_judged_art48_effectiveness', $event)">
              <span>No, quello in corso è il primo piano triennale presentato</span>
            </label>
            <label>
              <input type="radio" :value="1" :checked="1 === surveyData.company_has_judged_art48_effectiveness" @change="setRadio('company_has_judged_art48_effectiveness', $event)">
              <span>Sì</span>
            </label>
          </fieldset>
        </div>
      </div>
    </template>

    <template v-if="isUniversita">
      <div class="question">
        <span class="question-title">Il vostro Ateneo presenta offerte formative, progetti di ricerca o progetti di formazione relativi agli studi di genere?</span>
        <p>Indicare per ogni Area CUN il numero di attività relative offerte</p>
        <table class="table w-100">
          <thead>
          <tr>
            <th rowspan="2"></th>
            <th colspan="6">Didattica</th>
            <th colspan="3">Ricerca</th>
            <th colspan="2">Formazione</th>
          </tr>
          <tr>
            <th v-for="activity in universityActivities" :key="activity.id" style="max-width: 100px">{{ activity.name }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="cunArea in cunAreas" :key="cunArea.id">
            <th>{{ cunArea.name }}</th>
            <td style="max-width: 100px" v-for="activity in universityActivities" :key="cunArea.id + '__' + activity.id">
              <input
                style="max-width: 90px; margin-left: 5px; margin-right: 5px" type="number" :min="0"
                :value="surveyData['company_university_gender_activit__' + cunArea.id + '__' + activity.id]"
                @input="setTextAsInteger('company_university_gender_activit__' + cunArea.id + '__' + activity.id, $event)"
              >
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="question">
        <span class="question-title">Nelle attività di Terza Missione dell'Ateneo sono state realizzate attività connesse alle tematiche di genere?</span>
        <fieldset class="radio-set">
          <label>
            <input type="radio" name="gender_studies_third_mission" :checked="0 === surveyData.gender_studies_third_mission" @change="setRadio('gender_studies_third_mission', $event)" :value="0">
            <span>No</span>
          </label>
          <label>
            <input type="radio" name="gender_studies_third_mission" :checked="1 === surveyData.gender_studies_third_mission" @change="setRadio('gender_studies_third_mission', $event)" :value="1">
            <span>Sì</span>
          </label>
          <div class="extra" v-if="surveyData.gender_studies_third_mission === 1">
            <label>
              Specificare:
              <input v-if="edit" type="text" :value="surveyData.gender_studies_third_mission_what" @input="setText('gender_studies_third_mission_what', $event)">
              <span v-else class="d-block fake-input fake-input-text">{{ surveyData.gender_studies_third_mission_what }}</span>
            </label>
          </div>
        </fieldset>
      </div>
    </template>

    <div class="question">
      <span class="question-title">Esiste un <span data-explained-by-term @click="showTerm('organismi-di-parita')">organismo di parità</span>?</span>

      <fieldset class="radio-set">
        <label>
          <input type="radio" :value="0" :checked="0 === surveyData.company_has_equality_authority" @change="setRadio('company_has_equality_authority', $event)">
          <span>No</span>
        </label>
        <label>
          <input type="radio" :value="1" :checked="1 === surveyData.company_has_equality_authority" @change="setRadio('company_has_equality_authority', $event)">
          <span>Sì</span>
        </label>
      </fieldset>

      <div class="question indented" v-if="isUniversita && 1 === surveyData.company_has_equality_authority">
        <span class="question-title">Specificare Organismi, figure e/o delegati/e di parità presenti nel vostro Ateneo</span>
        <textarea class="longtext" :value="surveyData.company_has_equality_authority_what" @change="setText('company_has_equality_authority_what', $event)" />
      </div>
    </div>

    <template v-if="isNotUniversita">
      <div class="question">
        <span class="question-title">Avete nominato un/a <span data-explained-by-term @click="showTerm('consigliera-di-fiducia')">consigliere/a di fiducia</span>?</span>

        <fieldset class="radio-set">
          <label>
            <input type="radio" :value="0" :checked="0 === surveyData.company_has_female_confidence_councillor" @change="setRadio('company_has_female_confidence_councillor', $event)">
            <span>No</span>
          </label>
          <label>
            <input type="radio" :value="1" :checked="1 === surveyData.company_has_female_confidence_councillor" @change="setRadio('company_has_female_confidence_councillor', $event)">
            <span>Sì</span>
          </label>
        </fieldset>
      </div>

      <div class="question">
        <span class="question-title">Avete un consiglio di amministrazione?</span>

        <fieldset class="radio-set">
          <label>
            <input type="radio" :value="0" :checked="0 === surveyData.company_has_management_board" @change="setRadio('company_has_management_board', $event)">
            <span>No</span>
          </label>
          <label>
            <input type="radio" :value="1" :checked="1 === surveyData.company_has_management_board" @change="setRadio('company_has_management_board', $event)">
            <span>Sì</span>
          </label>
        </fieldset>

        <div class="indented" v-if="surveyData.company_has_management_board === 1">
          <div class="number-row">
            <div class="number-row-question">
              <span class="question-title">Indicare la composizione del CDA</span>
            </div>
            <div class="number-row-answer">
              <div class="number-row-answer-male">
                <label>
                  <span>M</span>
                  <input type="number" :min="0" :checked="surveyData.company_nb_cda_members_M" @change="setRadio('company_nb_cda_members_M', $event)">
                </label>
              </div>
              <div class="number-row-answer-female">
                <label>
                  <span>F</span>
                  <input type="number" :min="0" :checked="surveyData.company_nb_cda_members_F" @change="setRadio('company_nb_cda_members_F', $event)">
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="isUniversita">
      <div class="question">
        <span class="question-title">Specificare se nel vostro Ateneo ci sono figure o sono utilizzati strumenti di tutela contro le
discriminazioni, le molestie e le molestie sessuali:</span>
        <fieldset class="checkbox-set">
          <label>
            <input type="checkbox" name="company_has_female_confidence_councillor_1" :checked="1 === surveyData.company_has_female_confidence_councillor_1" @change="setCheckbox('company_has_female_confidence_councillor_1', $event)">
            <span>Consigliere/a di fiducia</span>
          </label>
          <label>
            <input type="checkbox" name="company_has_female_confidence_councillor_2" :checked="1 === surveyData.company_has_female_confidence_councillor_2" @change="setCheckbox('company_has_female_confidence_councillor_2', $event)">
            <span>Sportello di consulenza psicologica</span>
          </label>
          <label>
            <input type="checkbox" name="company_has_female_confidence_councillor_3" :checked="1 === surveyData.company_has_female_confidence_councillor_3" @change="setCheckbox('company_has_female_confidence_councillor_3', $event)">
            <span>Comitato etico</span>
          </label>
          <label>
            <input type="checkbox" name="company_has_female_confidence_councillor_other" :checked="1 === surveyData.company_has_female_confidence_councillor_other" @change="setCheckbox('company_has_female_confidence_councillor_other', $event)">
            <span>Altro</span>
          </label>
          <div class="extra" v-if="1 === surveyData.company_has_female_confidence_councillor_other">
            <label>Specificare: <input type="text" :value="surveyData.company_has_female_confidence_councillor_other_what" @input="setText('company_has_female_confidence_councillor_other_what', $event)"></label>
          </div>
        </fieldset>
      </div>

      <div class="question">
        <span class="question-title">Il CUG si relazione periodicamente con i seguenti soggetti:</span>
        <fieldset class="checkbox-set">
          <label>
            <input type="checkbox" name="cug-relationship_1" :checked="1 === surveyData.cug_relationship_1" @change="setCheckbox('cug_relationship_1', $event)" :value="1">
            <span>Consigliere/a di fiducia</span>
          </label>
          <label>
            <input type="checkbox" name="cug-relationship_2" :checked="1 === surveyData.cug_relationship_2" @change="setCheckbox('cug_relationship_2', $event)" :value="1">
            <span>Sportello psicologico</span>
          </label>
          <label>
            <input type="checkbox" name="cug-relationship_3" :checked="1 === surveyData.cug_relationship_3" @change="setCheckbox('cug_relationship_3', $event)" :value="1">
            <span>Medico competente</span>
          </label>
          <label>
            <input type="checkbox" name="cug-relationship_4" :checked="1 === surveyData.cug_relationship_4" @change="setCheckbox('cug_relationship_4', $event)" :value="1">
            <span>RLS</span>
          </label>
          <label>
            <input type="checkbox" name="cug-relationship_other" :checked="1 === surveyData.cug_relationship_other" @change="setCheckbox('cug_relationship_other', $event)" :value="1">
            <span>Altro</span>
          </label>
          <div class="extra" v-if="1 === surveyData.cug_relationship_other">
            <label>Specificare: <input type="text" :value="surveyData.cug_relationship_other_what" @input="setText('cug_relationship_other_what', $event)"></label>
          </div>
        </fieldset>
      </div>
    </template>
    <InfoModal v-if="$modals.terms" modal-class="term-modal" :title="term" @close="closeTerm">
      <div v-html="termText"></div>
    </InfoModal>
    <InfoModal v-if="$modals.laws" modal-class="law-modal" :title="law" @close="closeLaw">
      <h2>{{ lawTitle }}</h2>
      <div v-html="lawText"></div>
    </InfoModal>
  </div>
</template>

<script>

import surveyMixin from '@/mixins/surveyMixin.js';
import stepTitleFilter from '@/filters/stepTitleFilter.js';

import QuestionText from '@/views/components/QuestionText.vue';
import QuestionRadio from '@/views/components/QuestionRadio.vue';
import QuestionNumberRow from '@/views/components/QuestionNumberRow.vue';
import InfoModal from '@/libs/Modals/components/InfoModal.vue';
import { mapGetters } from 'vuex';
import VueSelect from 'vue-select';

export default {
  mixins: [surveyMixin],
  filters: {
    stepTitle: stepTitleFilter,
  },
  components: {
    QuestionText,
    QuestionRadio,
    QuestionNumberRow,
    InfoModal,
    VueSelect,
  },
  props: {
    edit: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      universityActivities: [
        { id: 'corsi-laurea', name: 'Corsi di laurea' },
        { id: 'master', name: 'Master' },
        { id: 'phd', name: 'PhD' },
        { id: 'attivita-formative', name: 'Attività formative' },
        { id: 'moduli-attivita-formative', name: 'Moduli all\'interno di attività formative' },
        { id: 'seminari', name: 'Seminari' },

        { id: 'progetti-nazionali', name: 'Progetti nazionali' },
        { id: 'progetti-europei', name: 'Progetti europei' },
        { id: 'progetti-internazionali', name: 'Progetti internazionali' },

        { id: 'corsi-personale', name: 'Corsi per il personale universitario' },
        { id: 'eventi-conferenze', name: 'Eventi e/o conferenze' },
      ],
    };
  },
  computed: {
    ...mapGetters('survey', {
      referenceYear: 'referenceYear',
    }),
    canFillUniData () {
      return this.surveyData.company_university && this.referenceYear;
    },
    university () {
      return this.universities.find(u => u.id === this.surveyData.company_university);
    },
  },
  methods: {
    setUniversity (value) {
      if (value === null) {
        this.setText('company_university', {
          target: {
            value: null,
          }
        });

        return;
      }

      this.setText('company_university', {
        target: {
          value: value.id,
        }
      });
    },
    fillUniData () {
      this.$api.getUniData(this.surveyData.company_university, this.referenceYear)
        .then(res => res.data?.survey || {})
        .then(survey => {
          this.batchUpdateSurveyData(survey);
        })
      ;
    },
  },
};

</script>
